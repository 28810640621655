<template>
  <div>
    <!-- proxyaccountList 代理人提现明细 -->
    <en-table-layout tips :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!--提现申请时间-->
          <div class="conditions">
            <div>提现申请时间：</div>
            <el-date-picker
              v-model="advancedForm.times"
              :editable="false"
              style="width:260px"
              value-format="timestamp"
              type="daterange"
              :default-time="['00:00:00', '23:59:59']"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="{disabledDate: MixinDisabledDate}"
            ></el-date-picker>
            <!-- 提现状态选择 -->
            <el-select
              class="ipt-default"
              style="width:150px;margin-left:10px"
              placeholder="提现状态选择"
              v-model="advancedForm.settle_status"
              clearable
            >
              <el-option value="1" label="提现中"></el-option>
              <el-option value="2" label="提现成功"></el-option>
              <el-option value="3" label="提现失败"></el-option>
            </el-select>
          </div>

          <!--关键字-->
          <div class="conditions">
            <div>关键字选择：</div>
            <el-select
              class="ipt-default"
              style="width:120px"
              v-model="advancedForm.keyword_type"
              clearable
            >
              <el-option value="settle_no" label="提现流水号"></el-option>
              <el-option value="mem_phone" label="代理人手机号"></el-option>
            </el-select>
            <el-input
              class="ipt-default"
              style="width: 200px"
              v-model="advancedForm.keyword"
              placeholder="请输入关键字进行搜索"
              clearable
            ></el-input>
            <el-button class="btn-default" @click="searchEvent">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="settle_no" label="提现流水号" />
        <el-table-column prop="member_name" label="代理人昵称" />
        <el-table-column prop="mem_phone" label="代理人手机号" />
        <el-table-column prop="withdrawal_money" label="提现金额">
          <template slot-scope="scope">{{ scope.row.withdrawal_money | unitPrice("￥")}}</template>
        </el-table-column>
        <el-table-column prop="fee_money" label="手续费">
          <template slot-scope="scope">{{ scope.row.fee_money | unitPrice("￥")}}</template>
        </el-table-column>
        <el-table-column prop="create_time" label="提现申请时间">
          <template
            slot-scope="scope"
          >{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column label="提现状态">
          <template
            slot-scope="scope"
          >{{ scope.row.settle_status===1?"提现中": scope.row.settle_status===2?"提现成功":"提现失败"}}</template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" />
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
// import * as API_Distribution from "@/api/distribution";
import * as API_Account from "@/api/account";
// import { RegExp } from "bms-common/ui-utils";

export default {
  name: "proxyaccountList",
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
        mem_type: 2,
      },
      /** 列表数据 */
      tableData: "",
      /** 高级搜索数据 */
      advancedForm: {
        times: "", // 时间
        settle_status: "", // 分类
        keyword_type: "", // 根据关键字
        keyword: "", // 根据关键字搜索
      },
    };
  },
  watch: {
    // "addMemberForm.region"(next, prev) {
    //   if (next && next.length) {
    //     this.$refs.addMemberForm.clearValidate("region");
    //   }
    // }
  },
  mounted() {
    this.GET_List();
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_List();
    next();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },

    /** 搜索事件触发 */
    searchEvent() {
      this.params.page_no = 1;
      delete this.params["settle_no"];
      delete this.params["mem_phone"];
      delete this.params.start_time;
      delete this.params.end_time;
      if (this.advancedForm.times) {
        this.params.start_time = this.advancedForm.times[0] / 1000;
        this.params.end_time = this.advancedForm.times[1] / 1000;
      }
      delete this.params.times;
      this.params[this.advancedForm.keyword_type] = this.advancedForm.keyword;
      this.params.settle_status = this.advancedForm.settle_status;
      this.GET_List();
    },

    /** 获取会员列表 */
    GET_List() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Account.getAccountList(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
div.toolbar-btns .conditions {
  margin-right: 0px;
}
</style>
